import classNames from 'classnames';
import PropTypes from 'prop-types';

import React from 'react';

import css from './OrloDirect.module.css';

/// Author id of the "Orlo M" author.
const ADMIN_USER_ID = process.env.REACT_APP_SHARETRIBE_ADMIN_USER_ID;

const OrloDirect = props => {
    const { className, author } = props;

    const showOrloDirect = author?.id?.uuid == ADMIN_USER_ID;
    if (!showOrloDirect) return null;
    return (
        <div className={classNames(className, css.orloDirect)}>
            <b>ORLO</b>&nbsp;Direct
        </div>
    );
}


OrloDirect.defaultProps = { className: null, author: null };

const { string, object } = PropTypes;

OrloDirect.propTypes = { className: string, author: object };

export default OrloDirect;